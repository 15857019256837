import { ISubscription } from 'types/subscription';

import { createSelector } from 'reselect';
import { RootReducer } from '../rootReducer';

export const selectSubscriptions = (state: RootReducer) => state.subscriptions;

const selectCancellationCandidate = createSelector(
    selectSubscriptions,
    (subscriptions: any) => subscriptions.cancellationCandidate as ISubscription
);

const selectCancelReason = createSelector(selectSubscriptions, (subscriptions: any) => subscriptions.cancelReason);

export { selectCancellationCandidate, selectCancelReason };
