import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import {
    delay,
    put,
    take,
    takeLatest,
    fork,
    cancel,
    cancelled,
    all,
    call,
    takeEvery,
    select,
} from 'redux-saga/effects';
import { Task } from 'redux-saga';
import { CancelledEffect } from '@redux-saga/core/effects';

import api from 'api';

import {
    setSubscriptions,
    setSubscriptionsFetchingStatus,
    cancelSubscription,
    fetchSubscriptions,
    pauseSubscription as pauseSubscriptionAction,
    resumeSubscription as resumeSubscriptionAction,
    resetSubscription as resetSubscriptionAction,
    updateSubscription as updateSubscriptionAction,
    setSubscriptionCancelReminder,
    sendFeedback,
    setIsDiscountLoading,
    setDiscountSubscription,
    fetchDiscountSubscriptions,
} from './actions';
import { notifyError, notify } from '../notifications/actions';

import {
    CANCEL_SUBSCRIPTION,
    DISCARD_SUBSCRIPTION_CANCELLATION,
    FETCH_DISCOUNT_SUBSCRIPTION,
    FETCH_USER_SUBSCRIPTIONS,
    PAUSE_SUBSCRIPTION,
    RESET_SUBSCRIPTION,
    RESUME_SUBSCRIPTION,
    SEND_SUBSCRIPTION_FEEDBACK,
    SET_CANCEL_REMINDER,
    UPDATE_SUBSCRIPTION,
} from './actionTypes';

import sentry from 'services/Sentry/SentryInstance';

import { sortFullAccessFirst } from './helpers';

import { IDiscountSubscription, ISubscription } from 'types/subscription';
import { ICancelSubscription } from './types';

import { selectCancelReason } from './selectors';

function* getSubscription({ payload }: ReturnType<typeof fetchSubscriptions>) {
    try {
        const response: ISubscription[] = yield call(api.subscriptions.getSubscriptions);

        yield put(setSubscriptions(sortFullAccessFirst(response)));
        yield put(setSubscriptionsFetchingStatus(false));
        payload.onSuccess?.(response);
    } catch (error) {
        notifyError('getSubscription error');
    }
}

function* getDiscountSubscription({ payload }: ReturnType<typeof fetchDiscountSubscriptions>) {
    const { external_id, discount_type, onSuccess, onError } = payload;

    try {
        yield put(setIsDiscountLoading(true));
        const response: IDiscountSubscription = yield call(api.subscriptions.getDiscountSubscriptions, {
            external_id,
            discount_type,
        });

        yield put(setDiscountSubscription(response));
        yield put(setIsDiscountLoading(false));
        if (onSuccess) yield call(onSuccess);
    } catch (error) {
        yield put(setIsDiscountLoading(false));
        if (onError) yield call(onError);
    }
}

function* updateSubscription({ payload }: ReturnType<typeof updateSubscriptionAction>) {
    const { updatedSubscription, onSuccess, onError } = payload;
    const reason: string = yield select(selectCancelReason);

    try {
        yield call(api.subscriptions.updateSubscription, updatedSubscription);
        yield put(setDiscountSubscription(null));
        yield put(sendFeedback({ data: { reason } }));
        yield call(onSuccess);
    } catch (error) {
        yield call(onError);
    }
}

function* resetSubscription({ payload }: ReturnType<typeof resetSubscriptionAction>) {
    const { product, external_id, onSuccess, onError, discount_type } = payload;

    try {
        yield call(api.subscriptions.resetSubscription, {
            external_id,
            product,
            discount_type,
        });

        yield delay(1000);
        yield call(onSuccess);
        yield put(fetchSubscriptions());
    } catch (error) {
        yield call(onError);
    }
}

function* makeSubscriptionCancelling({ payload }: ReturnType<typeof cancelSubscription>) {
    try {
        const workerTask: Task = yield fork(callUnsubscribe, payload);

        yield take(DISCARD_SUBSCRIPTION_CANCELLATION);
        yield cancel(workerTask);
    } catch (error) {
        payload.onError();
    }
}

function* callUnsubscribe(payload: ICancelSubscription) {
    const reason: string = yield select(selectCancelReason);

    try {
        yield delay(3000);

        yield call(api.subscriptions.unsubscribe, {
            id: payload.externalId,
            provider: payload.paymentProvider,
        });

        yield put(fetchSubscriptions());
        yield put(setDiscountSubscription(null));
        yield put(sendFeedback({ data: { reason } }));
        payload.onSuccess();
    } catch (error: any) {
        sentry.logError(`subscription_cancellation_error | ${error?.message}`, SENTRY_APP, ERROR_LEVELS.CRITICAL, {
            reason: 'subscription_cancellation_response_error',
            details: error,
            externalId: payload?.externalId,
            payload,
        });

        payload.onError();
    } finally {
        const isCancelled: CancelledEffect = yield cancelled();

        if (isCancelled) {
            yield put(notify('subscription.cancellation.response.abort'));

            payload.onCancel();
        }
    }
}

function* pauseSubscription({ payload }: ReturnType<typeof pauseSubscriptionAction>) {
    const { pause_type, id, provider, onSuccess, onError } = payload;

    try {
        yield call(api.subscriptions.pauseSubscription, {
            id,
            provider,
            pause_type,
        });

        yield put(fetchSubscriptions({ onSuccess }));
    } catch (error) {
        yield call(onError, error as string);
    }
}

function* resumeSubscription({ payload }: ReturnType<typeof resumeSubscriptionAction>) {
    const { id, provider, onSuccess, onError } = payload;

    try {
        yield call(api.subscriptions.resumeSubscription, { id, provider });

        yield put(fetchSubscriptions({ onSuccess }));
    } catch (error) {
        yield call(onError, error as string);
    }
}

function* setReminder({ payload }: ReturnType<typeof setSubscriptionCancelReminder>) {
    try {
        const response: string = yield call(api.subscriptions.setReminder, {
            external_id: payload.externalId,
            provider: payload.paymentProvider,
        });

        if (response !== 'success') {
            throw new Error('Subscription reminder is not set');
        }

        payload.onSuccess();
    } catch (error: any) {
        console.error('subscription_set_reminder_error with external_id', payload.externalId);
        sentry.logError(`subscription_set_reminder_error | ${error?.message}`, SENTRY_APP, ERROR_LEVELS.CRITICAL, {
            reason: 'subscription_cancellation_response_error',
            details: error,
            externalId: payload?.externalId,
            payload,
        });
        payload.onError();
    } finally {
        const isCancelled: CancelledEffect = yield cancelled();

        if (isCancelled) {
            payload.onCancel();
        }
    }
}

function* sendSubscriptionFeedback({ payload }: ReturnType<typeof sendFeedback>) {
    const { data, onSuccess, onError } = payload;

    try {
        yield call(api.subscriptions.sendFeedback, data);
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (error) {
        if (onError) {
            yield call(onError);
        }
    }
}

export default function* watchSubscriptions() {
    yield all([
        takeEvery(FETCH_DISCOUNT_SUBSCRIPTION, getDiscountSubscription),
        takeLatest(FETCH_USER_SUBSCRIPTIONS, getSubscription),
        takeLatest(CANCEL_SUBSCRIPTION, makeSubscriptionCancelling),
        takeLatest(PAUSE_SUBSCRIPTION, pauseSubscription),
        takeLatest(UPDATE_SUBSCRIPTION, updateSubscription),
        takeLatest(RESUME_SUBSCRIPTION, resumeSubscription),
        takeLatest(RESET_SUBSCRIPTION, resetSubscription),
        takeLatest(SET_CANCEL_REMINDER, setReminder),
        takeLatest(SEND_SUBSCRIPTION_FEEDBACK, sendSubscriptionFeedback),
    ]);
}
