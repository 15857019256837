import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import React, { useState, useRef, useEffect } from 'react';

import { setCancelReason } from 'store/subscriptions/actions';

import { reasons } from './constants';

import { cancelFlow_reasonsContinueClick, cancelFlow_reasonsScreenLoad } from 'services/analytics/trackers/cancelFlow';

import { useCancelSubscription, useSubscriptions } from 'hooks';

import OutlinedButton from 'components/OutlinedButton';
import ModalHeader from 'components/ModalHeader';
import Modal from 'components/Modal';
import { Text, Button, Box, Image } from 'components/library';

import * as S from './styled';

import loader from 'assets/img/loader.svg';

import { ICancellationReasonModal } from 'types/modals';

const CancellationReasonModal = ({ onCancel, onSubmit, onClose, isFreezeSubscription }: ICancellationReasonModal) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isDiscountLoading } = useSubscriptions();
    const { cancellationCandidate } = useCancelSubscription();

    const cancellationReasonBtn = useRef<null | HTMLDivElement>(null);

    const [reason, setReason] = useState<string | null>(null);

    const handleSelectReason = (shortKey: string) => setReason(shortKey);

    const handleContinue = () => {
        if (reason) {
            if (cancellationCandidate) {
                cancelFlow_reasonsContinueClick({
                    reason,
                    action: 'continue',
                    subscription_id: cancellationCandidate.external_id,
                });
            }
            dispatch(setCancelReason(reason));
            onSubmit(reason);
        }
    };

    const handleCancel = () => {
        if (cancellationCandidate) {
            cancelFlow_reasonsContinueClick({
                reason: reason!,
                action: 'cancel',
                subscription_id: cancellationCandidate.external_id,
            });
        }

        onCancel();
    };

    const handleClose = () => {
        if (cancellationCandidate) {
            cancelFlow_reasonsContinueClick({
                reason: reason!,
                action: 'close',
                subscription_id: cancellationCandidate.external_id,
            });
        }

        onClose();
    };

    useEffect(() => {
        if (cancellationCandidate) {
            cancelFlow_reasonsScreenLoad({ subscription_id: cancellationCandidate.external_id });
        }
    }, [cancellationCandidate]);

    const titleKey = 'subscription.cancellationReason.why';

    return (
        <Modal isOpen fullscreen customStyles={{ content: { overflow: isDiscountLoading ? 'hidden' : 'scroll' } }}>
            <ModalHeader onClose={handleClose} />
            <S.Container>
                {isDiscountLoading && (
                    <S.LoaderContainer>
                        <Image maxWidth={24} center alt="loader" src={loader} mb={24} isForceVisible />
                    </S.LoaderContainer>
                )}
                <Box paddingTop={20} paddingBottom={100}>
                    <Text type="h4" medium center mb={24}>
                        <Trans i18nKey={titleKey} components={{ 1: <span style={{ color: '#1975FF' }} /> }} />
                    </Text>
                    <Box mb={32}>
                        {reasons.map(({ title, shortKey }) => (
                            <S.ReasonItem
                                key={shortKey}
                                $isSelected={shortKey === reason}
                                onClick={() => handleSelectReason(shortKey)}
                                data-locator={isFreezeSubscription ? 'freezeSubOption' : 'cancelSubOption'}
                            >
                                <S.RadioChecker $isSelected={shortKey === reason} className="radio-checker" />
                                <Text type="large-text" medium text={t(title)} />
                            </S.ReasonItem>
                        ))}
                    </Box>

                    <div ref={cancellationReasonBtn}>
                        <Button
                            onClick={handleContinue}
                            disabled={!reason}
                            mb={20}
                            text="basics.continue"
                            dataLocator="obContinue"
                        />
                    </div>
                    <OutlinedButton
                        dataLocator="discountOfferCancelSubscriptionButton"
                        text={t('subscription.text.cancel')}
                        onClick={handleCancel}
                    />
                </Box>
            </S.Container>
        </Modal>
    );
};

export default CancellationReasonModal;
